import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import { Tabs, Input, Form, Button } from "antd";
import { ucFirst } from "../utils";

const langs = ["it", "en", "de", "es", "fr"];

const keysToObj = (keys) => {
	let obj = {};
	keys.forEach((k) => {
		langs.forEach((l) => {
			obj[`${k.key}__${l}`] = k.translations.filter(
				(t) => t.lang == l
			)[0].value;
		});
	});
	return obj;
};

const objToKeys = (obj) => {
	let arr = [];
	Object.keys(obj).forEach((k) => {
		let lang = k.split("__")[1];
		let key = k.split("__")[0];
		if (arr.filter((i) => i.key == key).length == 0) {
			arr.push({ key, translations: [] });
		}
		arr
			.filter((i) => i.key == key)[0]
			.translations.push({ lang, value: obj[k] });
	});
	return arr;
};

const SingleInput = ({ value, onChange }) => {
	const [edit, setEdit] = useState(false);
	const [val, setVal] = useState(
		value
			? RichTextEditor.createValueFromString(value, "html")
			: RichTextEditor.createEmptyValue()
	);

	return (
		<>
			{edit && (
				<RichTextEditor className="text-editor" value={val} onChange={setVal} />
			)}
			{!edit && <p dangerouslySetInnerHTML={{ __html: value }}></p>}

			<Button
				size="small"
				onClick={(e) => {
					if (edit) {
						onChange(val.toString("html"));
					}
					setEdit((s) => !s);
				}}
			>
				{edit ? "Ok" : "Modifica"}
			</Button>
		</>
	);
};

const SingleKey = ({ key1, lang, values, setLang, setValues, type }) => (
	<div style={{ marginBottom: 40 }}>
		<label style={{ fontWeight: "bold" }}>{ucFirst(key1)}</label>
		<Tabs onChange={(t) => setLang(t)} defaultActiveKey={lang}>
			{langs.map((l) => (
				<Tabs.TabPane key={l} tab={l.toUpperCase()}>
					<SingleInput
						type={type}
						value={values[`${key1}__${l}`]}
						onChange={(e) => {
							setValues((v) => ({ ...v, [`${key1}__${l}`]: e }));
						}}
					/>
				</Tabs.TabPane>
			))}
		</Tabs>
	</div>
);

const IntStrings = ({ k, item, onChange }) => {
	let initialValues = {};

	let translatedItem = keysToObj(item[k]);
	console.log(translatedItem);
	const [lang, setLang] = useState(langs[0]);

	Object.keys(translatedItem).forEach((a) => {
		initialValues[a] = translatedItem[a] || "";
	});

	const [values, setValues] = useState(initialValues);

	useEffect(() => {
		onChange({
			[k]: objToKeys(values),
		});
		console.log(k, objToKeys(values));
	}, [values]);

	return (
		<>
			<Form.Item name={k} style={{ display: "none" }}>
				<Input />
			</Form.Item>
			{item[k].map(({ key, type }) => (
				<SingleKey
					type={type}
					setLang={setLang}
					setValues={setValues}
					key1={key}
					values={values}
					lang={lang}
				/>
			))}
		</>
	);
};

export default IntStrings;
