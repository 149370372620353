import React from "react";
import { Menu, Layout, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { auth } from "../firebase";

const Header = () => {
	return (
		<Layout.Header className="site-layout-background" style={{ padding: 0 }}>
			<Menu mode="horizontal">
				<Menu.Item>
					<Link to="/">
						<h1 className="logo" style={{ margin: 0 }}>
							Alto Salento
						</h1>
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/collection/immobili">Immobili</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/collection/posts">Posts</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/collection/categorie">Categorie</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/collection/richieste">Richieste</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/collection/pagine">Pagine</Link>
				</Menu.Item>
				{/* <Button href="https://altosalentoimmobiliare.com">
                        Website<LinkOutlined />
                    </Button> */}
				<Button onClick={(e) => auth.signOut()}>Logout</Button>
			</Menu>
		</Layout.Header>
	);
};

export default Header;
