import React, { useState, useEffect, useCallback, useRef } from "react";
import {
	Typography,
	Card,
	Spin,
	List as AntdList,
	Form,
	Input,
	Row,
	Col,
	Button,
	Switch,
	Select,
	Radio,
	Tabs,
	Descriptions,
	InputNumber,
	Alert,
	message,
	Popconfirm,
	Badge,
	Tag,
	Dropdown,
	Menu,
	Result,
} from "antd";
import { Link, Router, useHistory, useRouteMatch } from "react-router-dom";
import Axios from "axios";
import {
	mapToCollection,
	beautifyLabel,
	ucFirst,
	generatePdf,
	getDataUri,
} from "../utils";

import { Upload, Modal } from "antd";
import {
	PlusOutlined,
	CloudDownloadOutlined,
	UploadOutlined,
} from "@ant-design/icons";

import { fields } from "../fields";
import LocationFinder from "./LocationFinder";
import CustomCheckbox from "./CustomCheckbox";
import IntContent from "./IntContent";
import RichTextEditor from "react-rte";
import { saveAs } from "file-saver";

import JSZip from "jszip";
import Calendar from "./Calendar";
import dayjs from "dayjs";
import CustomUpload from "./UploadGrid";
import IntStrings from "./IntStrings";

import config from "../config";
const { baseUrl } = config;
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

const PicturesWall = ({ gallery, id, collection, item }) => {
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [fileList, setFileList] = useState(gallery);
	const history = useHistory();
	const match = useRouteMatch();

	const handleCancel = () => setPreviewVisible(false);

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewVisible(true);
		setPreviewImage(file.url || file.preview);
	};

	const handleChange = ({ fileList, file }) => {
		if (file && file.response && file.response.ok && id == "new") {
			history.push(
				`/collection/${match.params.collection}/${file.response.itemId}`
			);
		}
		setFileList(fileList);
	};

	const isFirstTime = useRef(true);

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div className="ant-upload-text">Upload</div>
		</div>
	);
	return (
		<div className="clearfix">
			<CustomUpload
				setFileList={setFileList}
				initialState={fileList
					.map((i) => ({ source: i.url, name: i.name }))
					.reverse()}
				customRequest={({ uid, onSuccess, file, onError, onProgress }) => {
					const data = new FormData();
					data.append("file", file);
					const config = {
						headers: {
							"content-type":
								"multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
						},
					};
					Axios.post(`${baseUrl}/image/${collection}/${id}`, data, config)
						.then((res) => {
							onSuccess(uid, {
								source: res.data.url,
								name: res.data.ok,
							});
							message.success("Immagine aggiunta!");
						})
						.catch((err) => {
							console.log(err);
						});
					return {
						abort: () => {},
					};

					// const reader = new FileReader();
					// reader.readAsDataURL(file);
					// reader.onload = () => {
					//   onSuccess(uid, {
					//     source: reader.result,
					//   });
					// };

					// return {
					//   abort() {
					//     //source.cancel()
					//   },
					// };
				}}
				onChange={async (e) => {
					if (!isFirstTime.current) {
						console.log(e);
						await Axios.post(`${baseUrl}/${collection}`, {
							_id: id,
							gallery: e.map((i) => ({
								url: i.source,
								name: i.source.split("/").pop(),
							})),
						});
					}
					isFirstTime.current = false;
				}}
				onDeleted={async (image) => {
					await Axios.delete(
						`${baseUrl}/image/${collection}/${id}/${image.name}`
					);
					message.success("Immagine rimossa");
				}}
			/>

			{/* <Upload
        action={`${baseUrl}/image/${collection}/${id}`}
        customRequest={(options) => {
          const data = new FormData();
          data.append("file", options.file);
          const config = {
            headers: {
              "content-type":
                "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
            },
          };
          Axios.post(options.action, data, config)
            .then((res) => {
              options.onSuccess(res.data, options.file);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
        listType="picture-card"
        fileList={fileList}
        multiple
        onPreview={handlePreview}
        onRemove={async (file) => {
          await Axios.delete(
            `${baseUrl}/image/${collection}/${id}/${
              file.url ? file.url.split("/").pop() : file.response.ok
            }`
          );
          message.success("Immagine rimossa");
        }}
        onChange={handleChange}
      >
        {uploadButton}
      </Upload> */}
			<Modal
				width={1000}
				key="modal"
				visible={previewVisible}
				footer={null}
				onCancel={handleCancel}
			>
				<img alt="example" style={{ width: "100%" }} src={previewImage} />
			</Modal>
		</div>
	);
};

const View = ({ match }) => {
	// console.log(match)
	const collection = match.params.collection;
	const id = match.params.id;

	const [loading, setLoading] = useState(false);
	const [item, setItem] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [error, setError] = useState(false);
	const [values, setValues] = useState({});
	const [pdf, setPdf] = useState(false);

	const [exporting, setExporting] = useState(false);

	const exportZip = async (watermark = false) => {
		setExporting(true);
		const zip = new JSZip();
		zip.file("locandina.pdf", await generatePdf(item, "blob"));
		let a = 0;
		for (let i of item.gallery) {
			a++;
			const imageBlob = await fetch(
				`https://images.salento-immobiliare.myservers.app/insecure/${
					watermark ? "wm:.3:ce:0:0:.25/" : ""
				}plain/${i.url}@jpg`
			).then((response) => response.blob());

			// create a new file from the blob object
			const imageFile = new File([imageBlob], `${a}.jpg`);
			zip.file(`images/${a}.jpg`, imageFile);
		}
		zip.generateAsync({ type: "blob" }).then(function (blob) {
			saveAs(blob, item.cod + ".zip");
		});
		// let images = item.gallery.map(i => {

		// })
		setExporting(false);
	};

	useEffect(() => {
		(async () => {
			if (id === "new") return setItem({ gallery: [], hide_ann: true });
			setLoading(true);
			let { data } = await Axios.get(
				`${baseUrl}/${mapToCollection(collection)}/${id}`
			);
			setItem(data);
			setValues(data);
			setLoading(false);
		})();
	}, [collection, id]);

	const onSubmit = async (formValues) => {
		console.log("VALUES", values);
		console.log("formValues", formValues);
		console.log("merged", { ...values, ...formValues });

		delete values.gallery_ordering;
		delete formValues.gallery_ordering;

		setLoadingSubmit(true);
		setError(false);
		try {
			let { data } = await Axios.post(
				`${baseUrl}/${mapToCollection(collection)}`,
				{
					...values,
					...formValues,
				}
			);
			message.success("Elemento salvato!");
			if (!item._id) history.push(`/collection/${collection}/${data.itemId}`);
		} catch (e) {
			message.error("Error!");
			console.log(e);
			setError(e);
		}

		setLoadingSubmit(false);
	};

	const history = useHistory();
	const deleteElemento = async () => {
		try {
			let { res } = await Axios.delete(
				`${baseUrl}/${mapToCollection(collection)}/${item._id}`
			);
			message.success("Elemento eliminato!");
			history.push("/collection/" + collection);
		} catch (e) {
			console.log(e);
			setError(e);
		}
	};

	const [form] = Form.useForm();

	if (loading || !item) return <Spin />;

	const layout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 18 },
	};

	const fieldsGroups = Object.keys(fields[collection])
		.map((k) => fields[collection][k].group)
		.filter((value, index, self) => self.indexOf(value) === index && value);
	console.log(fieldsGroups);

	const renderField = (k) => {
		const field = fields[collection][k];
		switch (field.type) {
			case "textarea":
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						rules={field.required ? [{ required: true }] : []}
					>
						<Input.TextArea placeholder="Inserisci valore" />
					</Form.Item>
				);
			case "date":
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						rules={field.required ? [{ required: true }] : []}
					>
						<p>{dayjs(item[k]).format("DD/MM/YYYY HH:mm:ss")} </p>
					</Form.Item>
				);
			case "string":
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						rules={field.required ? [{ required: true }] : []}
					>
						<Input placeholder="Inserisci valore" />
					</Form.Item>
				);
			case "radio":
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						rules={field.required ? [{ required: true }] : []}
					>
						<Radio.Group>
							{field.enum.map((o) => (
								<Radio value={o}>{beautifyLabel(o)}</Radio>
							))}
						</Radio.Group>
					</Form.Item>
				);
			case "boolean":
				if (field.icon)
					return (
						<Form.Item
							name={k}
							style={{ display: "inline-block", margin: 0, padding: 0 }}
							rules={field.required ? [{ required: true }] : []}
						>
							<CustomCheckbox k={k} field={field} />
						</Form.Item>
					);
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						valuePropName={"checked"}
						rules={field.required ? [{ required: true }] : []}
					>
						<Switch />
					</Form.Item>
				);
			case "enumeration":
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						rules={field.required ? [{ required: true }] : []}
					>
						<Select
							placeholder="Seleziona"
							options={
								typeof field.enum[0] === "string"
									? field.enum.map((v) => ({
											label: beautifyLabel(v),
											value: v,
											key: v,
									  }))
									: field.enum
							}
						/>
					</Form.Item>
				);

			case "location":
				return <LocationFinder value={item} onChange={form.setFieldsValue} />;
			case "float":
				return (
					<Form.Item
						name={k}
						label={ucFirst(field.label || k)}
						rules={field.required ? [{ required: true }] : []}
					>
						<InputNumber style={{ minWidth: 200 }} placeholder="0" />
					</Form.Item>
				);
			case "richtext":
			case "int-string":
				return (
					<IntContent
						field={field}
						k={k}
						item={item}
						onChange={form.setFieldsValue}
					/>
				);
			case "int-strings":
				return (
					<IntStrings
						field={field}
						k={k}
						item={item}
						onChange={form.setFieldsValue}
					/>
				);
			case "calendar":
				return (
					<Form.Item
						name={k}
						rules={field.required ? [{ required: true }] : []}
					>
						<Calendar field={field} k={k} item={item} />
					</Form.Item>
				);

			case "text":
				return (
					<Form.Item
						label={ucFirst(field.label || k)}
						name={k}
						rules={field.required ? [{ required: true }] : []}
					>
						<Input.TextArea />
					</Form.Item>
				);
		}
	};

	return (
		<Form {...layout} initialValues={item} onFinish={onSubmit} form={form}>
			<Row justify="space-between">
				<Col span={18}>
					<Typography.Title level={2}>
						{ucFirst(item.title__it || "Nuovo")}
					</Typography.Title>
				</Col>
				<Col>
					{item.draft && <Tag color="orange">Draft</Tag>}
					{collection === "immobili" && (
						<Button
							loading={pdf}
							onClick={(e) => {
								setPdf(true);
								generatePdf(item);
								setTimeout(() => setPdf(false), 1000);
							}}
							size="large"
							icon={<CloudDownloadOutlined />}
						>
							Locandina
						</Button>
					)}
					{collection === "immobili" && (
						<Dropdown
							overlay={
								<Menu>
									<Menu.Item onClick={(e) => exportZip()}>
										Senza watermark
									</Menu.Item>
									<Menu.Item onClick={(e) => exportZip(true)}>
										Con watermark
									</Menu.Item>

									<Menu.Item
										onClick={(e) => {
											const copyToClipboard = (str) => {
												const el = document.createElement("textarea");
												el.value = str;
												document.body.appendChild(el);
												el.select();
												document.execCommand("copy");
												document.body.removeChild(el);
											};
											copyToClipboard(
												`http://api.altosalentorealestate.com/immagini_immobile/${item.cod}`
											);
										}}
									>
										Copia link immagini
									</Menu.Item>
								</Menu>
							}
						>
							<Button
								loading={exporting}
								size="large"
								icon={<CloudDownloadOutlined />}
							>
								Export
							</Button>
						</Dropdown>
					)}
				</Col>
			</Row>
			<Row gutter={30}>
				<Col span={15}>
					<Card
						title={
							<Row style={{ marginBottom: 0 }}>
								<Col flex="auto">
									<Form.Item
										labelCol={2}
										label="Nascosto"
										name="hide_ann"
										valuePropName="checked"
									>
										<Switch></Switch>
									</Form.Item>
								</Col>
								<Col flex="auto">
									<Form.Item
										labelCol={2}
										label="In evidenza"
										name="in_evidenza"
										valuePropName="checked"
									>
										<Switch></Switch>
									</Form.Item>
								</Col>
								<Col style={{ textAlign: "right" }}>
									{!!item._id && (
										<Popconfirm
											title="Sicuro di volerlo eliminare?"
											onConfirm={deleteElemento}
											onCancel={() => {}}
											okText="Si"
											cancelText="No"
										>
											<Button
												style={{ marginRight: 20 }}
												type="danger"
												size="large"
											>
												ELIMINA
											</Button>
										</Popconfirm>
									)}
									<Button
										loading={loadingSubmit}
										htmlType="submit"
										type="primary"
										size="large"
									>
										SALVA
									</Button>
								</Col>
							</Row>
						}
					>
						{error && <Alert type="error" message={error.toString()}></Alert>}

						{fieldsGroups.length > 1 && (
							<Tabs>
								{fieldsGroups.map((t) => (
									<Tabs.TabPane tab={t} key={t}>
										{Object.keys(fields[collection])
											.filter((k) => fields[collection][k].group == t)
											.map(renderField)}
									</Tabs.TabPane>
								))}
							</Tabs>
						)}

						{fieldsGroups.length == 1 &&
							Object.keys(fields[collection])
								.filter((k) => fields[collection][k].group == fieldsGroups[0])
								.map(renderField)}
					</Card>
					{collection == "immobili" && (
						<Card title="Documents" style={{ marginTop: 20 }}>
							{!item._id && (
								<Result title="Salva le informazioni prima di caricare documenti"></Result>
							)}
							{item._id && (
								<Upload
									// onDownload={(file) => {
									// 	window.location.href = file.url;
									// }}
									onRemove={async (file) => {
										try {
											await Axios.delete(
												`${baseUrl}/document/${collection}/${id}/${file.url
													.split("/")
													.slice(-1)
													.pop()}`
											);
											message.success("Documnto rimosso");
										} catch (e) {}
									}}
									action={`${baseUrl}/document/${collection}/${id}`}
									defaultFileList={item.documents?.map(
										(i) =>
											({
												...i,
												uid: i.name,
											} || [])
									)}
								>
									<Button icon={<UploadOutlined />}>Click to Upload</Button>
								</Upload>
							)}
						</Card>
					)}
				</Col>
				{item.gallery && (
					<Col span={9}>
						<Card title="Gallery">
							{item._id && (
								<PicturesWall
									item={item}
									collection={mapToCollection(collection)}
									id={item._id || "new"}
									gallery={item.gallery.map((i) => ({
										...i,
										key: i.name,
										status: "",
										uid: i.name,
									}))}
								/>
							)}
							{!item._id && (
								<Result title="Salva le informazioni prima di caricare le immagini"></Result>
							)}
						</Card>
					</Col>
				)}
			</Row>
		</Form>
	);
};

export default View;
