import React, { useState } from "react";
import RichTextEditor from "react-rte";
import { Tabs, Input, Form } from "antd";
import { ucFirst } from "../utils";
import Axios from "axios";
import config from "../config";
const { baseUrl } = config;
const langs = ["it", "en", "de", "es", "fr"];

const IntContent = ({ k, field, item, onChange }) => {
	let initialValues = {};
	langs.forEach((l) => {
		if (field.type == "richtext")
			initialValues[l] = item[`${k}__${l}`]
				? RichTextEditor.createValueFromString(item[`${k}__${l}`], "html")
				: RichTextEditor.createEmptyValue();
		else initialValues[l] = item[`${k}__${l}`] || "";
	});

	const copyFromTo = async (f, t) => {
		let { data } = await Axios.post(`${baseUrl}/translate`, {
			obj: {
				text: values[f].toString(field.type == "richtext" ? "html" : true),
			},
			source: f,
			target: [t],
		});

		console.log(
			data[t].text,
			RichTextEditor.createValueFromString(data[t].text, "html"),
			data[t].text.toString(field.type == "richtext" ? "html" : true)
		);
		setValues((v) => ({
			...v,
			[t]:
				field.type == "richtext"
					? RichTextEditor.createValueFromString(data[t].text, "html")
					: data[t].text,
		}));
		onChange({
			[k + "__" + t]: data[t].text,
		});
	};

	const [lang, setLang] = useState(langs[0]);
	const [values, setValues] = useState(initialValues);
	return (
		<div style={{ marginBottom: 40 }}>
			{langs.map((l) => (
				<Form.Item style={{ display: "none" }} name={`${k}__${l}`}>
					<Input />
				</Form.Item>
			))}
			<label>{ucFirst(k)}</label>
			<Tabs onChange={(t) => setLang(t)} defaultActiveKey={lang}>
				{langs.map((l) => (
					<Tabs.TabPane key={l} tab={l.toUpperCase()}>
						{field.type == "richtext" ? (
							<>
								<RichTextEditor
									className="text-editor"
									value={values[l]}
									onChange={(e) => {
										setValues((v) => ({ ...v, [l]: e }));
										onChange({
											[k + "__" + l]: e.toString(
												field.type == "richtext" ? "html" : true
											),
										});
									}}
								/>
								{!values[l].getEditorState().getCurrentContent().hasText() &&
									l !== "it" && (
										<a onClick={(e) => copyFromTo("it", l)}>
											Copia e traduci da Italiano
										</a>
									)}
							</>
						) : (
							<Input
								allowClear={true}
								addonAfter={
									values[l] == "" && l !== "it" ? (
										<a onClick={(e) => copyFromTo("it", l)}>
											Copia e traduci da Italiano
										</a>
									) : null
								}
								placeholder="Inserisci traduzione"
								value={values[l]}
								onChange={(e) => {
									const { value } = e.target;
									setValues((v) => ({ ...v, [l]: value }));
									onChange({
										[k + "__" + l]: value,
									});
								}}
							></Input>
						)}
					</Tabs.TabPane>
				))}
			</Tabs>
		</div>
	);
};

export default IntContent;
