import React, { useState } from 'react'
import { ucFirst } from '../utils'

const CustomCheckbox = ({k,field, value, onChange}) => {
    return (
        <div className="customCheck" onClick={e => {
            onChange(!value)
        }} style={{width: 100, transition:'all .3s', margin:'0px 10px 10px 0', cursor:'pointer', border: `2px solid ${value == true ? '#1DA57A':'rgba(0,0,0,.1)'}`,borderRadius:4, height: 100, display:'inline-flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:10}}>
            <span style={{fontFamily:'comfort',transition:'all .3s', color:value == true ? '#1DA57A':'rgba(0,0,0,.2)', fontSize: 30}}>{field.icon}</span>
            <span style={value==true?{color: '#1DA57A',transition:'all .3s'}:{transition:'all .3s'}}>{ucFirst(field.label || k)}</span>
        </div>
    )
}

export default CustomCheckbox
