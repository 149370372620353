import React from "react";
import RUG from "react-upload-gallery";

// Add style manually
import "./pictureGrid.scss"; // or scss

const CustomUpload = (props) => {
  return <RUG {...props} />;
};

export default CustomUpload;
