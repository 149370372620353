import React, { useState } from 'react'
import Calendar from 'react-calendar';
import './Calendar.scss';
import * as dayjs from 'dayjs'
import classes from './View.module.scss'
const dateAlreadyClicked = (dates, date) => dates.some(d => dayjs(date).isSame(dayjs(d), 'day'))
const datesExcept = (dates, date) => dates.filter(d => !dayjs(date).isSame(dayjs(d), 'day'))

const CalendarField = ({item, onChange, k}) => {

    const [dates, setDates] = useState(item.booking ||[])


    const onClickDay = date => {
        console.log(date)
        // if day is already clicked, remove it from state
        if (dateAlreadyClicked(dates, date)) setDates(datesExcept(dates, date))
        else setDates([...dates, date])

        onChange([...dates, date])
      }
    
      const tileClassName = ({ date }) => {
        const classNames = ['dayTile']
        // give active days a special class
        if (dateAlreadyClicked(dates, date)) return [classes.activeDay, ...classNames]
        return classNames
      }

    return (
        <div>
            <Calendar
                tileClassName={tileClassName}
                onClickDay={onClickDay}
                // tileDisabled={({date}) =>  {
                //     console.log(date)
                //     return dates.some(d => dayjs(date).isSame(dayjs(d), 'day'))
                // }}
            />
        </div>
    )
}

export default CalendarField
