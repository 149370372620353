import React, { useState, useEffect } from "react";
import { Layout, Spin } from 'antd';
import "./styles/index.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Overview from "./components/Overview";
import Header from "./components/Header";
import List from "./components/List";
import View from "./components/View";
import Login from "./components/Login";
import {  connect } from 'unistore/react'
import { store } from "./store/store";
import{auth} from './firebase'
const { Content, Footer, Sider } = Layout;


const Dashboard = () => (
        <Layout className="site-layout" style={{ minHeight: '100vh' }}>
            <Header />
            <Content style={{padding: '40px 20px'}} className="container">
            
                <Switch>
                <Route path="/collection/:collection/:id" exact component={View}/>
                  <Route path="/collection/:collection" exact component={List}/>
                  <Route path="/" exact component={Overview}/>
                </Switch>
              
            </Content>
            <Footer style={{ textAlign: 'center' }}></Footer>
        </Layout>
)

const App = connect('logged,init')(({init, logged}) => {


    useEffect(()=>{
      auth.onAuthStateChanged(userAuth => {
        store.setState({logged: userAuth? true:false, user:userAuth, init:true})
      });
    })

    if(!init)
      return <Spin/>

    return (
      
        <BrowserRouter>
          {logged && <Dashboard />}
          {!logged && <Login />}
        </BrowserRouter>
    );
  })



  
  export default App