import React from "react";

const SearchInput = ({
  value,
  handleInputChange,
  hasResults,
  handleFocus,
  Input,
  handleBlur,
  searchHint
}) => (
  <div style={{width:'100%', height: '100%'}}>
   
    <Input
      style={{
        'height':'100%',
        'width':'100%',
      }}
      displayName="input"
      hasResults={hasResults}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder={searchHint}
      value={value}
      onChange={handleInputChange}
    />
  </div>
);

export default SearchInput;
