import firebase from "firebase/app";
import "firebase/auth";
import Axios from "axios";

const firebaseConfig = {
	apiKey: "AIzaSyBYV2Ro3BrCrkqfI7L8AoMS33umDLhyQKw",
	authDomain: "salento-immobili-1579971823288.firebaseapp.com",
	databaseURL: "https://salento-immobili-1579971823288.firebaseio.com",
	projectId: "salento-immobili-1579971823288",
	storageBucket: "salento-immobili-1579971823288.appspot.com",
	messagingSenderId: "465962496397",
	appId: "1:465962496397:web:be52d2246af69ff92b4dc4",
	measurementId: "G-2VDWE1JLLW",
};
firebase.initializeApp(firebaseConfig);

Axios.interceptors.request.use(
	async (config) => {
		config.headers.authorization = await firebase
			.auth()
			.currentUser.getIdToken(true);
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export const auth = firebase.auth();
