export const fields = {
	categorie: {
		title: {
			type: "int-string",
			group: "info",
		},
		affitto: {
			type: "boolean",
			group: "info",
		},
	},

	pagine: {
		strings: {
			type: "int-strings",
			group: "content",
		},
	},

	richieste: {
		createdAt: {
			type: "date",
			label: "Data",
			group: "info",
		},
		email: {
			type: "string",
			label: "Email",
			group: "info",
		},
		name: {
			type: "string",
			label: "Nome",
			group: "info",
		},
		surname: {
			type: "string",
			label: "Cognome",
			group: "info",
		},
		message: {
			type: "textarea",
			label: "Messaggio",
			group: "info",
		},
		phone: {
			type: "string",
			label: "Telefono",
			group: "info",
		},
		from: {
			type: "string",
			label: "Richiesta dalla pagina",
			group: "info",
		},
		item: {
			type: "string",
			label: "Codice proprietà",
			group: "info",
		},
	},
	posts: {
		title: {
			type: "int-string",
			required: true,
			group: "info",
		},
		sub_title: {
			type: "int-string",
			required: true,
			group: "info",
		},
		content: {
			type: "richtext",
			required: true,
			group: "info",
		},
		gallery: [
			{
				url: String,
				name: String,
			},
		],
		old_id: Number,
		hide_ann: Boolean,
	},

	immobili: {
		iofferta: {
			required: true,
			label: "Immobile in",
			type: "enumeration",
			enum: ["vendita", "affitto"],
			group: "Generali",
		},
		cod: {
			required: true,
			type: "string",
			group: "Generali",
		},
		// "nome_imm": {
		//   "type": "string",
		// },
		classen: {
			label: "Classe energetica",
			group: "Dati tecnici",
			type: "radio",
			enum: [
				"non__specificato",
				"a3",
				"a4",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"esente",
				"a1",
				"a2",
				"in__richiesta",
			],
		},
		iPrezzo: {
			label: "Prezzo",
			group: "Generali",
			type: "float",
			unit: "€",
		},
		edificabile: {
			group: "Generali",
			type: "radio",
			enum: ["non__specificato", "no", "si"],
		},
		indirizzo: {
			group: "Indirizzo",
			type: "location",
		},
		localiz_exact: {
			group: "Indirizzo",
			type: "boolean",
		},
		// "status_price": {
		//   "type": "integer"
		// },
		// "info": {
		//   "type": "json"
		// },
		isupimmobile: {
			label: "Superfice immobile",
			type: "float",
			unit: "mq",
			group: "Dati tecnici",
		},
		// "sup_abitazione": {
		//     "label": "Superfice immobile",
		//     "type": "float",
		//     "group": "Dati tecnici"
		// },
		sup_deposito: {
			label: "Superfice deposito",
			type: "float",
			unit: "mq",
			group: "Dati tecnici",
		},
		sup_ampliamento: {
			label: "Superfice ampliabile",
			type: "float",
			unit: "mq",
			group: "Dati tecnici",
		},
		sup_pavim: {
			label: "Superfice pavimentata",
			type: "float",
			unit: "mq",
			group: "Dati tecnici",
		},
		isupterreno: {
			label: "Superfice terreno",
			type: "float",
			unit: "mq",
			group: "Dati tecnici",
		},
		// "icamere": {
		//     "label":"Superfice immobile",
		//     "type": "float",
		//     "group":"Dati tecnici"
		// },
		camereletto: {
			label: "Numero di camere",
			type: "float",
			group: "Dati tecnici",
		},
		cucina: {
			label: "Numero cucine",
			type: "float",
			group: "Dati tecnici",
		},
		tipo_cucina: {
			type: "enumeration",
			enum: [
				"nessuno",
				"abitabile",
				"angolo__cottura",
				"cucinotto",
				"semiabitabile",
			],
			group: "Dati tecnici",
		},
		iBagni: {
			label: "Numero Bagni",
			type: "float",
			group: "Dati tecnici",
		},
		giudizio: {
			group: "Generali",
			type: "enumeration",
			enum: [
				"da__ristrutturare",
				"da__ammodernare",
				"in__fase__di__ristrutturazione",
				"abitabile",
				"buono",
				"ristrutturato",
				"da__completare",
				"eccellente",
				"nuova__costruzione",
			],
		},
		imareUB: {
			label: "Mare",
			group: "Indirizzo",
			type: "boolean",
			icon: "E",
		},
		iCampagnaUB: {
			label: "Campagna",
			group: "Indirizzo",
			type: "boolean",
			icon: "J",
		},
		iPeriferiaUB: {
			label: "Periferia",
			group: "Indirizzo",
			type: "boolean",
			icon: "K",
		},
		iUrbanoUB: {
			label: "Urbano",
			group: "Indirizzo",
			type: "boolean",
			icon: "I",
		},
		iStoricoUB: {
			label: "Storico",
			group: "Indirizzo",
			type: "boolean",
			icon: "L",
		},
		iZPanUB: {
			label: "Panoramico",
			group: "Indirizzo",
			type: "boolean",
			icon: "M",
		},
		icostakm: {
			type: "float",
			unit: "km",
			label: "Distanza costa",
			group: "Indirizzo",
		},
		iospedalekm: {
			type: "float",
			unit: "km",
			label: "Distanza ospedale",
			group: "Indirizzo",
		},
		iferroviakm: {
			type: "float",
			unit: "km",
			label: "Distanza ferrovia",
			group: "Indirizzo",
		},
		iaeroportokm: {
			type: "float",
			unit: "km",
			label: "Distanza areoporto",
			group: "Indirizzo",
		},
		scalo_km: {
			type: "float",
			unit: "km",
			label: "Distanza scalo",
			group: "Indirizzo",
		},
		// "hide_ann": {
		//     "label":"Nascondi annuncio",
		//     "type": "boolean"
		// },
		autostrada_km: {
			type: "float",
			unit: "km",
			label: "Distanza autostrada",
			group: "Indirizzo",
		},
		capoluogo_km: {
			type: "float",
			unit: "km",
			label: "Distanza capoluogo",
			group: "Indirizzo",
		},
		iurbanokm: {
			type: "float",
			unit: "km",
			label: "Distanza centro",
			group: "Indirizzo",
		},
		piscina: {
			type: "boolean",
			icon: "P",
			group: "Extra",
		},
		giardino: {
			type: "boolean",
			icon: "g",
			group: "Extra",
		},
		terrazzo: {
			type: "boolean",
			icon: "U",
			group: "Extra",
		},
		veranda: {
			type: "boolean",
			icon: "V",
			group: "Extra",
		},
		piazzale: {
			type: "boolean",
			icon: "p",
			group: "Extra",
		},
		climatizzazione: {
			type: "boolean",
			icon: "R",
			group: "Extra",
		},
		arredato: {
			type: "boolean",
			icon: "A",
			group: "Extra",
		},
		fornolegna: {
			type: "boolean",
			icon: "f",
			group: "Extra",
		},
		rinnovabili: {
			type: "boolean",
			icon: "S",
			group: "Extra",
		},
		camino: {
			type: "boolean",
			icon: "X",
			group: "Extra",
		},
		ascensore: {
			type: "boolean",
			icon: "Q",
			group: "Extra",
		},
		vista_mare: {
			type: "boolean",
			icon: ":",
			group: "Extra",
		},
		balcone: {
			type: "boolean",
			icon: "|",
			group: "Extra",
		},
		riscaldamento: {
			type: "enumeration",
			enum: ["nessuno", "autonomo", "centralizzato", "senza__riscaldamento"],
			group: "Extra",
		},
		postoauto: {
			type: "enumeration",
			enum: ["nessuno", "singolo", "doppio", "posto__auto"],
			group: "Extra",
		},

		youtube: {
			type: "string",
			group: "Extra",
		},

		// "old_id": {
		//     "type": "integer"
		// },
		categoria_immobile: {
			group: "Generali",
			type: "enumeration",
			enum: ["residenziale", "commerciale", "non__specificato"],
		},
		tipologia: {
			group: "Generali",
			type: "enumeration",
			enum: [
				"masseria___tenuta",
				"villa",
				"casolare___lamia",
				"trullo",
				"terreno",
				"casa",
				"immobile__di__prestigio",
				"immobile__storico",
				"appartamento",
				"stabile___palazzo",
				"garage",
				"locale",
			],
		},
		genere: {
			group: "Generali",
			type: "enumeration",
			enum: [
				"rustico___casale___corte",
				"villa__singola",
				"non__specificato",
				"terreno__edificabile",
				"villa__bifamiliare",
				"casa__indipendente",
				"villa",
				"casa__tipica",
				"casa__semindipendente",
				"terreno__residenziale",
				"locale__commerciale",
				"villa__a__schiera",
				"attico",
				"masseria___tenuta",
				"villa__al__mare",
				"monolocale",
				"seminterrato",
			],
		},
		// "comune": {
		//   "type": "string"
		// },
		// "provincia": {
		//   "type": "string"
		// },
		// "regione": {
		//   "type": "string"
		// },
		// "frazione": {
		//   "type": "string"
		// },
		title: {
			group: "Contenuti",
			required: true,
			type: "int-string",
		},
		description: {
			group: "Contenuti",
			required: true,
			type: "richtext",
		},
		description_terreno: {
			group: "Contenuti",
			type: "richtext",
		},
		sviluppi: {
			group: "Contenuti",
			type: "richtext",
		},
		// "slug": {
		//   "type": "string",
		//   "required": true
		// },
		myTipoImm: {
			group: "Generali",
			model: "category",
			type: "enumeration",
			enum: [
				{
					value: "ville-e-trulli-con-piscina",
					label: "Ville e trulli con piscina",
					key: "ville-e-trulli-con-piscina",
				},
				{
					value: "terreni-e-trulli-ampliabili-entro-10-minuti-dal-mare",
					label: "Terreni e Trulli ampliabili entro 10 minuti dal mare",
					key: "terreni-e-trulli-ampliabili-entro-10-minuti-dal-mare",
				},
				{
					value: "ville-in-fase-di-realizzazione",
					label: "Ville in fase di realizzazione",
					key: "ville-in-fase-di-realizzazione",
				},
				{
					value: "ville-e-trulli-vista-mare-o-panoramici",
					label: "Ville e trulli vista mare o panoramici",
					key: "ville-e-trulli-vista-mare-o-panoramici",
				},
				{
					value: "masserie-e-casolari",
					label: "Masserie e Casolari",
					key: "masserie-e-casolari",
				},
				{
					value: "ville-e-trulli-abitabili-entro-10-20-minuti-dal-mare",
					label: "Ville e trulli abitabili entro 10- 20 minuti dal mare",
					key: "ville-e-trulli-abitabili-entro-10-20-minuti-dal-mare",
				},
				{
					value: "terreni-e-trulli-ampliabili-panoramici",
					label: "Terreni e trulli ampliabili  panoramici",
					key: "terreni-e-trulli-ampliabili-panoramici",
				},
				{
					value: "terreni-e-trulli-ampliabili-vista-mare",
					label: "Terreni e Trulli ampliabili vista mare ",
					key: "terreni-e-trulli-ampliabili-vista-mare",
				},
				{
					value: "terreni-e-trulli-ampliabili-entro-10-20-minuti-dal-mare",
					label: "Terreni e trulli ampliabili  entro 10-20 minuti dal mare ",
					key: "terreni-e-trulli-ampliabili-entro-10-20-minuti-dal-mare",
				},
				{
					value: "case-al-mare",
					label: "Case al mare",
					key: "case-al-mare",
				},
				{
					value: "abitazioni-centro-storico",
					label: "Abitazioni centro storico",
					key: "abitazioni-centro-storico",
				},
				{
					value: "abitazioni-citt-e-locali-commerciali",
					label: "Abitazioni città e locali commerciali",
					key: "abitazioni-citt-e-locali-commerciali",
				},
				{
					value: "luxury-ville-con-piscina",
					label: "Luxury Ville con piscina",
					key: "luxury-ville-con-piscina",
				},
				{
					value: "affitti-al-mare",
					label: "Affitti al mare",
					key: "affitti-al-mare",
				},
			],
		},

		zona: {
			group: "Generali",
			model: "zona",
			type: "enumeration",
			enum: [
				{
					value: "terra-dei-messapi",
					label: "Terra dei messapi",
					key: "terra-dei-messapi",
				},
				{
					value: "terre-di-taranto",
					label: "Terre di Taranto",
					key: "terre-di-taranto",
				},
				{
					value: "terre-di-bari",
					label: "Terre di Bari",
					key: "terre-di-bari",
				},
				{
					value: "zona-costiera",
					label: "Zona costiera",
					key: "zona-costiera",
				},
				{
					value: "murgia-vista-mare",
					label: "Murgia vista mare",
					key: "murgia-vista-mare",
				},
				{
					value: "basso-salento",
					label: "Basso salento",
					key: "basso-salento",
				},
			],
		},

		booking: {
			type: "calendar",
			group: "Booking",
		},
		notes: {
			type: "text",
			label: "Note private",
			group: "Notes",
		},
	},
};
