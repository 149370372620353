import React, { useState } from "react";
import { Descriptions, Input, Form } from "antd";
import SearchBox from "./SearchBox";

const LocationFinder = ({ value, onChange }) => {
	const [myValues, setMyValues] = useState(value);
	return (
		<div style={{ margin: "20px 0" }}>
			{["comune", "provincia", "regione", "info"].map((c) => (
				<Form.Item style={{ display: "none" }} name={c}>
					<Input />
				</Form.Item>
			))}
			<label style={{ marginBottom: 10 }}>Cerca indirizzo</label>
			<SearchBox
				id="locationSelect"
				query={""}
				input={Input}
				token={
					"pk.eyJ1IjoidG9ueHhkIiwiYSI6ImNrNnh6ODgwbDBlaGczZW8wc3lrajR2anoifQ.mdpns6xcMz4XJEVpDZ_a8A"
				}
				country="IT"
				callback={({ location, event }) => {
					console.log(location, {
						comune: location.text,
						provincia: location.context.filter((c) =>
							c.id.startsWith("region")
						)[0].text,
						regione: "Puglia",
						info: {
							type: "Point",
							coordinates: location.center,
						},
					});
					onChange({
						comune: location.text,
						provincia: location.context
							.filter((c) => c.id.startsWith("region"))[0]
							.text.replace("città metropolitana di", "")
							.replace("provincia di", "")
							.trim(),
						regione: "Puglia",
						info: {
							type: "Point",
							coordinates: location.center,
						},
					});
					setMyValues({
						comune: location.text,
						provincia: location.context
							.filter((c) => c.id.startsWith("region"))[0]
							.text.replace("città metropolitana di", "")
							.replace("provincia di", "")
							.trim(),
						regione: "Puglia",
						info: {
							type: "Point",
							coordinates: location.center,
						},
					});
				}}
				selectColor="#ef4836"
			/>
			<Descriptions bordered style={{ marginTop: 10 }} column={2}>
				<Descriptions.Item label="Comune">{myValues.comune}</Descriptions.Item>
				<Descriptions.Item label="Coordinate">
					{myValues.info
						? myValues.info.coordinates.map((i) => i.toFixed(3)).join(", ")
						: ""}
				</Descriptions.Item>
				<Descriptions.Item label="Provincia">
					{myValues.provincia}
				</Descriptions.Item>
				<Descriptions.Item label="Regione">
					{myValues.regione}
				</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

export default LocationFinder;
