import React, { useState, useEffect } from "react";
import {
	Typography,
	Card,
	Spin,
	List as AntdList,
	Row,
	Col,
	Button,
	Checkbox,
	Input,
	Form,
	Select,
	InputNumber,
	Switch,
	message,
} from "antd";
import { Link } from "react-router-dom";
import Axios from "axios";
import search, { mapToCollection } from "../utils";
import { PlusOutlined } from "@ant-design/icons";
import config from "../config";
const { baseUrl } = config;

const List = ({ match }) => {
	// console.log(match)
	const collection = match.params.collection;
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [items, setItems] = useState({ list: [], meta: {} });

	const [categories, setCategories] = useState([]);

	const [form] = Form.useForm();

	const [iofferta, setIofferta] = useState(["vendita", "affitto"]);
	const [status, setStatus] = useState([true, false]);
	const [query, setQuery] = useState("");

	const zoneOptions = [
		{ value: "tutti", label: "Tutte le zone" },
		{ value: "terre-di-taranto", label: "Terre di Taranto" },
		{ value: "valle-ditria", label: "Valle d'Itria" },
		{ value: "terre-di-bari", label: "Terre di Bari" },
		{ value: "zona-costiera", label: "Zona Costiera" },
		{ value: "terra-dei-messapi", label: "Terre dei Messapi" },
		{ value: "basso-salento", label: "Basso Salento" },
	];

	const seaOptions = [
		{ value: 0, label: "Qualsiasi distanza" },
		{ value: 0.5, label: "< 500m" },
		{ value: 1, label: "< 1km" },
		{ value: 3, label: "< 3km" },
		{ value: 10, label: "< 10km" },
		{ value: 20, label: "< 20km" },
	];

	const comuneOptions = [
		"Tutti",
		"Alberobello",
		"Avetrana",
		"Brindisi",
		"Carovigno",
		"Castellana Grotte",
		"Castrignano Del Capo",
		"Ceglie Messapica",
		"Cisternino",
		"Fasano",
		"Francavilla Fontana",
		"Latiano",
		"Locorotondo",
		"Martina Franca",
		"Mesagne",
		"Monopoli",
		"Ostuni",
		"Polignano a Mare",
		"Salve",
		"San Michele Salentino",
		"San Vito Dei Normanni",
		"Ugento",
		"Leuca",
		"Marina Di Pescoluse",
		"Pozzo Faceto",
		"Selva di Fasano",
	];

	const onSearch = async (values, skip = 0, limit = 10) => {
		setLoading(true);
		let data = await search({ filters: values, skip, limit });
		setItems(data);
		setLoading(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (collection == "immobili") {
			onSearch(form.getFieldsValue(), (page - 1) * perPage, perPage);

			// categories
			if (collection == "immobili" && categories.length == 0) {
				Axios.get(`${baseUrl}/categories`).then(({ data }) => {
					setCategories(data.list);
				});
			}
		}
	}, [collection, page, perPage]);

	useEffect(() => {
		if (collection !== "immobili") {
			(async () => {
				setLoading(true);
				let { data } = await Axios.get(
					`${baseUrl}/` + mapToCollection(collection),
					{
						params: {
							limit: perPage,
							iofferta: iofferta.join(","),
							skip: (page - 1) * perPage,
							hide_ann: status.join(","),
							cod: query,
						},
					}
				);
				setItems(data);
				setLoading(false);
			})();
		}
	}, [collection, page, iofferta, status, query, perPage]);

	const [updateDisabled, setUpdateDisabled] = useState(false);

	return (
		<div>
			<Row justify="space-between">
				<Col>
					<Typography.Title level={2}>
						{collection.toUpperCase()}
					</Typography.Title>
				</Col>
				<Col>
					{collection == "immobili" && (
						<Button
							style={{ marginRight: 40 }}
							target="_blank"
							disabled={updateDisabled}
							onClick={(e) => {
								Axios.get(
									"https://scraper.salento-immobiliare.myservers.app" +
										"/update"
								);
								message.success("Updated");
								setUpdateDisabled(true);
							}}
						>
							Aggiorna visibili
						</Button>
					)}
					{collection == "immobili" && (
						<Button
							style={{ marginRight: 40 }}
							target="_blank"
							href={`${baseUrl}/fb_xml`}
						>
							Download FB XML
						</Button>
					)}
					<Link to={`/collection/${collection}/new`}>
						<Button type="primary" size="large">
							<PlusOutlined /> AGGIUNGI {collection.toUpperCase()}
						</Button>
					</Link>
				</Col>
			</Row>
			{collection == "immobili" && (
				<>
					<Form
						id="searchForm"
						form={form}
						layout="inline"
						onFinish={onSearch}
						name="searchForm"
						initialValues={{
							iofferta: ["affitto", "vendita"],
							hide_ann: [false, true],
							cod: "",
							myTipoImm: "tutti",
							zona: "tutti",
							sea: 0,
							comune: "Tutti",
						}}
					>
						<Form.Item name="iofferta" label="Immobili in:">
							<Checkbox.Group>
								<Checkbox value="affitto">Affitto</Checkbox>
								<Checkbox value="vendita">Vendita</Checkbox>
							</Checkbox.Group>
						</Form.Item>
						<Form.Item name="hide_ann" label="Status:">
							<Checkbox.Group>
								<Checkbox value={false}>Visibile</Checkbox>
								<Checkbox value={true}>Nascosto</Checkbox>
							</Checkbox.Group>
						</Form.Item>
						<Form.Item name="cod" label="Codice">
							<Input placeholder="Cerca per codice"></Input>
						</Form.Item>
						<Form.Item name="myTipoImm" label="Categoria">
							<Select>
								<Select.Option value="tutti">Tutte le categorie</Select.Option>
								{categories.map((c) => (
									<Select.Option value={c.slug}>{c.title__it}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item name="zona" label="Zona">
							<Select>
								{zoneOptions.map((c) => (
									<Select.Option value={c.value}>{c.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item name="sea" label="Distanza dal mare">
							<Select>
								{seaOptions.map((c) => (
									<Select.Option value={c.value}>{c.label}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item name="comune" label="Comune">
							<Select>
								{comuneOptions.map((c) => (
									<Select.Option value={c}>{c}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item name="bathrooms" label="Numero di bagni">
							<InputNumber placeholder="0" />
						</Form.Item>
						<Form.Item name="bedrooms" label="Numero di stanze">
							<InputNumber placeholder="0" />
						</Form.Item>
						<Form.Item name="iZPanUB" label="Zona panoramica">
							<Switch></Switch>
						</Form.Item>
						<Form.Item name="vista_mare" label="Vista mare">
							<Switch></Switch>
						</Form.Item>
						<Button loading={loading} htmlType="submit" type="primary">
							Cerca
						</Button>
					</Form>
				</>
			)}
			{collection !== "immobili" && <Row justify="space-between"></Row>}
			{loading && (
				<AntdList
					grid={{ gutter: 16, column: 4 }}
					dataSource={[{}, {}, {}]}
					renderItem={(item) => (
						<AntdList.Item>
							<Card
								loading
								cover={<div style={{ height: 200, width: "100%" }} />}
							>
								<Card.Meta title={"Temp"} description={"temp"}></Card.Meta>
							</Card>
						</AntdList.Item>
					)}
				/>
			)}

			{!loading && (
				<AntdList
					grid={{ gutter: 16, column: 4 }}
					pagination={{
						total: items.meta.total,
						pageSize: perPage,
						onShowSizeChange: (e, a) => {
							console.log(a);
							setPerPage(a);
						},
						showTotal: (v) => `Total: ${v}`,
						onChange: (p) => setPage(p),
						current: items.meta.page,
						defaultCurrent: 1,
					}}
					dataSource={items.list}
					renderItem={(item) => (
						<AntdList.Item>
							<Link to={`/collection/${collection}/${item._id}`}>
								<Card
									hoverable
									cover={
										item.gallery && item.gallery.length ? (
											<img
												style={{ height: 200, objectFit: "cover" }}
												src={
													item.gallery[0]
														? item.gallery[0].url
														: "https://placehild.it/400x200"
												}
											/>
										) : (
											<div style={{ minHeight: 200, background: "#ccc" }}></div>
										)
									}
								>
									<Card.Meta
										title={item.title__it || item.email}
										description={item.cod || item.message?.slice(0, 300)}
									></Card.Meta>
								</Card>
							</Link>
						</AntdList.Item>
					)}
				/>
			)}
		</div>
	);
};

export default List;
