import React from "react";
import { Card, Typography, List } from "antd";
import { Link } from "react-router-dom";

const Overview = () => {
	return (
		<List
			grid={{ gutter: 16, column: 4 }}
			dataSource={["Immobili", "Posts", "Categorie", "Richieste", "Pagine"]}
			renderItem={(item) => (
				<List.Item>
					<Link to={`/collection/${item.toLowerCase()}`}>
						<Card>
							<Typography.Title>{item}</Typography.Title>
						</Card>
					</Link>
				</List.Item>
			)}
		/>
	);
};

export default Overview;
