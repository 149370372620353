import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Form, Input, Button, Card, Alert, Select } from "antd";
import { store } from "../store/store";
import { auth } from "../firebase";
import config from "../config";
const Login = () => {
	const [error, setError] = useState(false);

	const onFinish = (values) => {
		config.baseUrl = `https://${values.website}.salento-immobiliare.myservers.app`;

		auth
			.signInWithEmailAndPassword(values.email, values.password)
			.catch((e) => {
				setError(e.message);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="loginWrapper">
			<Card className="loginCard">
				<h1>Login</h1>
				<Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
					<Form.Item
						label="Website"
						name="website"
						rules={[{ required: true, message: "Please select a website" }]}
					>
						<Select>
							<Select.Option value="backend-affitti">
								holidayvillapuglia.com
							</Select.Option>
							<Select.Option value="backend">
								altosalentorealestate.com
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Username"
						name="email"
						rules={[{ required: true, message: "Please input your username!" }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: "Please input your password!" }]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item>
						<Button block type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
				{error && <Alert message={error} type="error" />}
			</Card>
		</div>
	);
};

export default Login;
