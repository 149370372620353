import QRCode from "qrcode";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Axios from "axios";
import config from "./config";
const { baseUrl } = config;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ucFirst = (s) => s.slice(0, 1).toUpperCase() + s.slice(1);
export const mapToCollection = (t) => {
	return {
		immobili: "properties",
		posts: "posts",
		categorie: "categories",
		richieste: "requests",
		pagine: "pages",
	}[t];
};
export const beautifyLabel = (l) =>
	ucFirst(l.replace(/___/gi, "/").replace(/__/gi, " "));

var decodeEntities = (function () {
	// this prevents any overhead from creating the object each time
	var element = document.createElement("div");

	function decodeHTMLEntities(str) {
		if (str && typeof str === "string") {
			// strip script/html tags
			str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
			str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
			element.innerHTML = str;
			str = element.textContent;
			element.textContent = "";
		}

		return str;
	}

	return decodeHTMLEntities;
})();

export const formatCurrency = (n = 0) => {
	return n <= 0
		? "Prezzo Riservato"
		: "€ " + new Intl.NumberFormat("it-IT").format(n);
};

export async function getDataUri(url) {
	return new Promise((resolve) => {
		var ImageToLoad = new Image();

		ImageToLoad.crossOrigin = "Anonymous";

		ImageToLoad.onError = function () {
			console.log('Cannot load image: "' + url + '"');
		};

		ImageToLoad.onload = function () {
			var canvas = document.createElement("canvas"),
				context = canvas.getContext("2d");
			canvas.width = ImageToLoad.width;
			canvas.height = ImageToLoad.height;
			context.drawImage(
				ImageToLoad,
				0,
				0,
				ImageToLoad.width,
				ImageToLoad.height
			);

			resolve([
				canvas.toDataURL("image/jpg"),
				{
					width: ImageToLoad.width,
					height: ImageToLoad.height,
				},
			]);
		};
		ImageToLoad.src = url;
	});
}

export const generatePdf = async (item, mode = "download") => {
	var docDefinition = {
		pageSize: {
			width: 595.28,
			height: 841.89,
		},
		pageMargins: [30, 30],

		content: [
			{
				columns: [
					{
						width: 150,
						image: "logo",
						margin: [0, 10, 0, 0],
					},
					{
						width: 595.28 - 60 - 150 - 80,
						alignment: "right",
						text: "",
					},
					{
						width: 80,
						alignment: "right",
						image: "qr",
					},
				],
			},
			{
				image: "main",
				width: 595.28 - 60,
			},
			{
				text: `#${item.cod} - ${item.comune} - ${formatCurrency(item.iPrezzo)}`,
				margin: [0, 20, 0, 0],
			},
			{
				text: decodeEntities(item["title__it"]),
				fontSize: 18,
				bold: true,
				margin: [0, 20, 0, 20],
			},
			decodeEntities(item["description__it"]),
		],
		images: {
			logo: (
				await getDataUri(
					"https://images.salento-immobiliare.myservers.app/insecure/plain/https://altosalentoimmobiliare.s3.fr-par.scw.cloud/o08K_eyz2nYD-GoaVZyvM.jpg"
				)
			)[0],
			main: (
				await getDataUri(
					`https://images.salento-immobiliare.myservers.app/insecure/rs:fill:1000:600/plain/${item.gallery[0].url}@jpg`
				)
			)[0],
			qr: await QRCode.toDataURL(
				`https://altosalentoimmobiliare.com/it/immobile/${item.old_id}/${item.slug}`
			),
		},
	};
	let pdf = await pdfMake.createPdf(docDefinition);
	if (mode === "download") pdf.download(item.cod + ".pdf");
	else {
		return new Promise((res) => pdf.getBlob((b) => res(b)));
	}
};

// function urlToPromise(url) {
// 	return new Promise(function (resolve, reject) {
// 		JSZipUtils.getBinaryContent(url, function (err, data) {
// 			if (err) {
// 				reject(err);
// 			} else {
// 				resolve(data);
// 			}
// 		});
// 	});
// }

// function downloadWithBlob(imageList, zipFilename = "photos.zip") {
// 	const zip = new JSZip();

// 	imageList.forEach((url, index) =>
// 		zip.file("image" + index + ".png", urlToPromise(url), { binary: true })
// 	);
// 	zip.generateAsync({ type: "blob" }).then(function (content) {
// 		saveAs(content, zipFilename);
// 	});
// }

export const shortCurrency = (v = 0) =>
	"€" +
	(v >= 1e6 ? v / 1e6 : v / 1e3).toFixed() +
	(v >= 1e6 ? "m" : v <= 1e3 ? "" : "k");

export default async function search({ filters, sort, limit = 10, skip = 0 }) {
	const params = {
		location: filters.location ? filters.location : null,
		iofferta: filters.iofferta.join(","),
		hide_ann: filters.hide_ann.join(","),
		myTipoImm:
			!filters.pageCategory || filters.pageCategory === "tutti"
				? null
				: filters.pageCategory,
		limit: limit,
		skip: skip,
		cod: filters.cod ? "/" + filters.cod + "/" : null,
		sort: sort,
		zona: !filters.zona || filters.zona === "tutti" ? null : filters.zona,
		vista_mare: filters.vista_mare,
		iZPanUB: filters.iZPanUB,
		booking: filters.booking || null,
		piscina: filters.piscina,
		comune:
			!filters.comune || filters.comune === "Tutti" ? null : filters.comune,
	};

	Object.keys(params).forEach(
		(key) =>
			(params[key] === null || typeof params[key] === "undefined") &&
			delete params[key]
	);

	const lessGreatParams = {
		"iPrezzo>=": filters.price ? filters.price.split(",")[0] : null,
		"iPrezzo<=":
			filters.price && parseFloat(filters.price.split(",")[1]) < 1e6
				? filters.price.split(",")[1]
				: null,
		"camereletto>=": filters.bedrooms || null,
		"iBagni>=": filters.bathrooms || null,

		"icostakm<=":
			filters.sea && parseFloat(filters.sea) > 0 ? filters.sea : null,
	};

	console.log(
		`${process.env.NEXT_PUBLIC_API_URL}/properties?${Object.keys(
			lessGreatParams
		)
			.filter((k) => lessGreatParams[k] === 0 || lessGreatParams[k])
			.map((k) => k + lessGreatParams[k])
			.join("&")}`,
		params
	);

	let { data } = await Axios.get(
		`${baseUrl}/properties?${Object.keys(lessGreatParams)
			.filter((k) => lessGreatParams[k] === 0 || lessGreatParams[k])
			.map((k) => k + lessGreatParams[k])
			.join("&")}`,
		{ params }
	);

	return data;
}
